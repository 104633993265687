/**
 * This component will be used on category pages for both sub-category and brand navigation. It includes the Image and Link_Huge components.
 *
 * @module views/components/CategoryCard
 * @memberof -Common
 */
import './CategoryCard.scss';

import React from 'react';

import classNames from 'classnames';
import PropTypes from 'prop-types';

import Image from '@ulta/core/components/Image/Image';
import Link_Huge from '@ulta/core/components/Link_Huge/Link_Huge';
import Text from '@ulta/core/components/Text/Text';

/**
 * Represents a CategoryCard component
 *
 * @method
 * @param {CategoryCardProps} props - React properties passed from composition
 * @returns CategoryCard
 */
export const CategoryCard = function( props ){
  return (
    <div className='CategoryCard'>
      <Link_Huge
        action={ props.action }
      >

        <div className={
          classNames( 'CategoryCard__NavType', {
            [`CategoryCard__NavType--${props.navType}`]: props.navType
          } )
        }
        >
          { props.image ? (
            <div className='CategoryCard__Image'>
              <Image
                aspectRatio='1:1'
                src={ props.image }
                { ...( props.imageMetaData && { metaData: props.imageMetaData } ) }
                shouldUseIntersectionObserver={ false }
              />
            </div>
          ) :
            `${props.allProductsLabel}`
          }
        </div>

        <Text
          textStyle='body-2'
          htmlTag='span'
          textAlign='center'
        >
          { props.shopAllLabel ? props.shopAllLabel : props.label }
        </Text>

      </Link_Huge>
    </div>
  );
};

/**
 * Property type definitions
 * @typedef CategoryCardProps
 * @type {object}
 * @property {string} navType - Sets the card/nav type (currently one of 'category', 'viewAll', 'brand')
 * @property {string} link - Sets the link location
 * @property {string} label - Sets the label text
 * @property {string} image - Sets the image location
 * @property {string} shopAllLabel - Sets the shop all label
 * @property {string} description - Sets the description
 * @property {string} allProductsLabel - Sets the all products label
 */
export const propTypes = {
  /** Sets the card/nav type (currently one of 'category', 'viewAll', 'brand') */
  navType: PropTypes.oneOf( ['category', 'viewAll', 'brand'] ),
  /** Sets the label text */
  label: PropTypes.string,
  /** Sets the image location */
  image: PropTypes.string,
  /** Sets the shop all label */
  shopAllLabel: PropTypes.string,
  /** Sets the description */
  description: PropTypes.string,
  /** Sets the all products label */
  allProductsLabel: PropTypes.string,
  /** Sets the all products action */
  action: PropTypes.object

};



CategoryCard.propTypes = propTypes;

export default CategoryCard;
